<template>
    <div class="container-fluid d-flex justify-content-center align-items-center flex-column">
        <h3 class="custom-header text-center mb-4 mt-4">Cargar CFDIs</h3>

        <div class="row bg-light-recibo p-5 shadow rounded w-75">
            <!-- Columna para el Año -->
            <div class="col-4 mb-3">
                <div class="form-group">
                    <label for="anio" class="form-label">Año:</label>
                    <input id="anio" type="number" class="form-control" v-model="state.anio"
                        placeholder="Ingrese el año" @change="onAnioChange" />
                </div>
            </div>

            <!-- Columna para el Periodo -->
            <div class="col-4 mb-3">
                <div class="form-group">
                    <label for="periodo" class="form-label">Periodo:</label>
                    <select id="periodo" class="form-select" v-model="state.periodo">
                        <option value="" disabled>-- Seleccione un periodo --</option>
                        <option :value="Periodos.SEMANAL">Semanal</option>
                        <option :value="Periodos.QUINCENAL">Quincenal</option>
                        <option :value="Periodos.MENSUAL">Mensual</option>
                    </select>
                </div>
            </div>

            <!-- Columna para mostrar información del periodo seleccionado -->
            <div class="col-4 mb-3">
                <div v-if="state.periodo === Periodos.SEMANAL">
                    <div class="form-group">
                        <label for="semanal" class="form-label">Semanas:</label>
                        <select id="semanal" class="form-select" v-model="state.semana">
                            <option value="" disabled>-- Seleccione la semana --</option>
                            <option v-for="s in semanas" :key="s.semana" :value="s.semana">{{ s.semana }}</option>
                        </select>
                    </div>
                </div>

                <div v-if="state.periodo === Periodos.QUINCENAL">
                    <div class="form-group">
                        <label for="quincenal" class="form-label">Quincenas:</label>
                        <select id="quincenal" class="form-select" v-model="state.quincena">
                            <option value="" disabled>-- Seleccione la quincena --</option>
                            <option v-for="q in quincenas" :key="q.quincena" :value="q.quincena">{{ q.quincena }}
                            </option>
                        </select>
                    </div>
                </div>

                <div v-if="state.periodo === Periodos.MENSUAL">
                    <div class="form-group">
                        <label for="mes" class="form-label">Meses:</label>
                        <select id="mes" class="form-select" v-model="state.mes">
                            <option value="" disabled>-- Seleccione el mes --</option>
                            <option v-for="mes in meses" :key="mes" :value="mes">{{ mes }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row w-75 rounded mt-5">
            <UploadRecibos @archivosCargados="recibirArchivos" :clearFile="sendclear"
                @update:clearFile="handleClearFileUpdate"></UploadRecibos>
        </div>

        <div class="row w-75 rounded mt-5 justify-content-end">
            <div class="col-md-3 text-right">
                <button @click="cargarArchivos" :disabled="!puedeCargarArchivos" class="btn btn-primary mb-3">
                    Cargar Archivos
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { Periodos } from "../../utils/enumeraciones";
import { RecibosService } from "@/services";
import UploadRecibos from "../partials/UploadRecibos.vue";

export default {
    data() {
        return {
            sendclear: false,
            semanas: [],
            quincenas: [],
            meses: [],
            archivosRecibidos: [],
            state: {
                anio: new Date().getFullYear(),
                periodo: '',
                semana: '',
                quincena: '',
                mes: '',
                archivos: []
            }
        }
    },
    components: { UploadRecibos },
    computed: {
        Periodos() {
            return Periodos;
        },
        puedeCargarArchivos() {
            // Verifica si hay archivos, si se ha seleccionado un periodo, y si está lleno uno de los campos relacionados
            const tieneArchivos = this.archivosRecibidos.length > 0;
            const tienePeriodo = this.state.periodo !== '';
            const tieneDetallePeriodo = this.state.semana !== '' || this.state.quincena !== '' || this.state.mes !== '';

            return tieneArchivos && tienePeriodo && tieneDetallePeriodo;
        }
    },
    created() {
        this.cargarDatosIniciales(this.state.anio);
    },
    methods: {
        async cargarDatosIniciales(anio) {
            try {
                const [semanasData, quincenasData, mesesData] = await Promise.all([
                    RecibosService.getSemanasByAnio(anio),
                    RecibosService.getQuincenasByAnio(anio),
                    RecibosService.getMesesByAnio(anio),
                ]);
                this.semanas = semanasData.data.semanas;
                this.quincenas = quincenasData.data.quincenas;
                this.meses = mesesData.data.meses;
            } catch (error) {
                console.error("Error al cargar los datos:", error);
            }
        },
        onAnioChange() {
            this.cargarDatosIniciales(this.state.anio);
        },
        recibirArchivos(archivos) {
            this.archivosRecibidos = archivos;
        },
        async cargarArchivos() {
            try {
                this.state.archivos = await Promise.all(
                    this.archivosRecibidos.map(archivo =>
                        new Promise((resolve) => {
                            const fileRead = new FileReader();
                            fileRead.onload = () => {
                                const base64Code = fileRead.result.split(',')[1]; // Eliminamos el prefijo "data:application/pdf;base64,"
                                resolve({
                                    name: archivo.name,
                                    base64code: base64Code
                                });
                            };
                            fileRead.readAsDataURL(archivo);
                        })
                    )
                );
                // Enviar los archivos al backend
                const response = await RecibosService.saveRecibo({
                    anio: this.state.anio,
                    periodo: this.state.periodo,
                    semana: this.state.semana,
                    quincena: this.state.quincena,
                    mes: this.state.mes,
                    archivos: this.state.archivos // Solo enviamos los archivos y los detalles relevantes
                });

                if (response.status === 200) {
                    this.clearState();
                    console.log('Recibos cargados y enviados con éxito.');
                }
            } catch (error) {
                console.error('Error al cargar los archivos:', error);
            }
        },
        handleClearFileUpdate(newValue) {
            this.sendclear = newValue;
        },
        clearState() {
            this.sendclear = !this.sendclear; // Activar la limpieza en el componente hijo

            this.state = {
                anio: new Date().getFullYear(),
                periodo: '',
                semana: '',
                quincena: '',
                mes: '',
                archivos: []
            }
        }
    }
}
</script>

<style scoped land="scss">
@import "../../styles/recibos.scss";
</style>