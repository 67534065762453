<template>
    <div>
        <div class="row">
            <!-- Columna para el input de subir archivos -->
            <div class="col-md-4">
                <button @click="triggerFileInput" class="btn btn-primary mb-3">
                    Seleccionar Recibos</button>
                <input ref="fileInput" type="file" @change="handleFileUpload" multiple accept=".pdf, .xml"
                    class="d-none" />
            </div>
        </div>
        <div class="row">

            <!-- Columna para mostrar la tabla de archivos seleccionados -->
            <div class="col-md-12">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th class="col-file">Archivo</th>
                            <th>Tipo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody v-if="files.length">
                        <tr v-for="(file, index) in files" :key="index">
                            <td>{{ file.name }}</td>
                            <td>{{ file.type }}</td>
                            <td>
                                <button @click="removeFile(index)" class="btn btn-danger btn-sm">
                                    Eliminar
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="3" class="text-center">No hay archivos seleccionados</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        clearFile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            files: [] // Array para almacenar los archivos seleccionados
        };
    },
    watch: {
        // Observar la prop clearFile para limpiar los archivos cuando sea true
        clearFile(newValue) {
            if (newValue) {
                console.log('Limpieza activada desde el padre');
                this.clearFiles();
            }
        }
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        // Manejar los archivos seleccionados
        handleFileUpload(event) {
            const selectedFiles = Array.from(event.target.files);

            // Filtrar archivos permitidos (.pdf y .xml)
            const validFiles = selectedFiles.filter(file =>
                file.type === 'application/pdf' || file.type === 'text/xml'
            );

            // Añadir archivos validados al estado
            this.files.push(...validFiles);
            this.$emit('archivosCargados', this.files);
        },

        // Eliminar archivo de la lista
        removeFile(index) {
            this.files.splice(index, 1);
            this.$emit('archivosCargados', this.files);
        },

        // Enviar los archivos
        async submitFiles() {
            try {
                const formData = new FormData();

                this.files.forEach(file => {
                    formData.append('files[]', file);
                });

                // Enviar los archivos al servidor (puedes ajustar la URL según tu backend)
                const response = await fetch('https://tu-api.com/subir-archivos', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    alert('Archivos subidos con éxito');
                    this.files = []; // Limpiar la lista de archivos
                } else {
                    alert('Error al subir los archivos');
                }
            } catch (error) {
                console.error('Error al subir los archivos:', error);
            }
        },
        // Limpiar la lista de archivos
        clearFiles() {
            this.files = [];
            this.$emit('archivosCargados', this.files);
            this.$emit('update:clearFile', false); // Reiniciar el valor de la prop
        }
    }
};
</script>

<style scoped>
.d-none {
    display: none;
}

.file-upload {
    max-width: 500px;
    margin: 0 auto;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
}

.file-upload {
    max-width: 900px;
    margin: 0 auto;
}

.table-hover tbody tr:hover {
    background-color: #f1f1f1;
}

thead th {
    background-color: #123d6a;
    color: white;
}

td {
    vertical-align: middle;
}

.col-file {
    max-width: 120px;
    /* Ajusta este valor según tu diseño */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-primary {
    background-color: #123d6a !important;
}
</style>